import { MenuItem } from 'primeng/api';

export const SIDEBAR_ITEMS: MenuItem[] = [
  {
    label: 'Gestión de Clientes',
    icon: 'pi pi-users',
    items: [
      {
        label: 'Lista de Clientes',
        routerLink: 'data/clients',
      },
    ],
  },
  {
    label: 'Gestión de Cupones',
    icon: 'pi pi-ticket',
    items: [
      {
        label: 'Creación de Cupones',
        routerLink: 'coupon-management/generate-coupon-codes',
      },
      {
        label: 'Lista de Cupones',
        routerLink: 'data/client-coupons',
      },
      {
        label: 'Tipos de Cupones',
        routerLink: 'data/coupons',
      },
    ],
  },
];
