import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private auth: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> 
  {
    return this.checkLogin(route);
  }

  checkLogin(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.authState$.pipe(
      filter((user) => user !== undefined),
      map((user) => {
        if (!user) {
          this.router.navigateByUrl('/auth');
          return false;
        }
        return true;
      })
    );
  }
  
}
