import { Injectable } from '@angular/core';
import { CDADocument, CDADocumentOmit } from '@cda-libs/cda-models';

/* Firebase Imports */
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  addDoc,
  doc,
  setDoc,
  DocumentReference,
  serverTimestamp,
  updateDoc,
  writeBatch,
  query,
  where,
  getDocs,
  getDoc,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private db = this.firestore.firestore;

  constructor(private firestore: AngularFirestore) {}

  public addCDADocumentAttributes(
    docData: Omit<CDADocument, CDADocumentOmit>,
    docId: string
  ) {
    return {
      ...docData,
      id: docId,
      createdAt: serverTimestamp(),
      updatedAt: null,
      deletedAt: null,
    };
  }

  public async getDocument(
    collectionPath: string,
    docId: string
  ): Promise<any> {
    const docRef = doc(this.db, collectionPath, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return null;
    }
  }

  public async getDocuments(collectionPath: string): Promise<any[]> {
    const colRef = collection(this.db, collectionPath);
    const docsSnap = await getDocs(colRef);

    if (docsSnap.empty) return null;

    const docsData = docsSnap.docs.map(doc => doc.data());
    return docsData;
  }

  public async createDocument(
    collectionPath: string,
    docData: Omit<CDADocument, CDADocumentOmit>,
    docId?: string
  ) {
    try {
      let completeDocData: CDADocument = {
        ...docData,
        createdAt: serverTimestamp(),
        updatedAt: null,
        deletedAt: null,
      };

      const collectionRef = collection(this.db, collectionPath);

      if (docId) {
        await setDoc(doc(collectionRef, docId), completeDocData);
        console.log('Document written with ID: ', docId);
      } else {
        const docRef: DocumentReference = await addDoc(
          collectionRef,
          completeDocData
        );
        console.log('Document written with ID: ', docRef.id);
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  public async updateDocument(
    collectionPath: string,
    docData: Omit<CDADocument, CDADocumentOmit>,
    docId: string
  ) {
    try {
      const completeDocData = { ...docData, updatedAt: serverTimestamp() };
      const docRef = doc(this.db, collectionPath, docId);

      await updateDoc(docRef, completeDocData);

      console.log('Document Updated Successfully');
    } catch (e) {
      console.error('Error updating document: ', e);
    }
  }

  public async deleteDocument(collectionPath: string, docId: string) {
    try {
      const docRef = doc(this.db, collectionPath, docId);

      await updateDoc(docRef, { deletedAt: serverTimestamp() });

      console.log('Document Updated Successfully');
    } catch (e) {
      console.error('Error deleting document: ', e);
    }
  }

  public async batchWrite() {
    const batch = writeBatch(this.db);

    await batch.commit();
  }

  public async checkUniqueFields(
    collectionPath: string,
    docField: string,
    docValue: any
  ): Promise<boolean> {
    const collectionRef = collection(this.db, collectionPath);
    const q = query(collectionRef, where(docField, '==', docValue));

    try {
      const querySnapshot = await getDocs(q);
      const clients = [];
      querySnapshot.forEach((doc) => {
        clients.push(doc.data());
      });
      const isAvailable = clients.length === 0;
      return isAvailable;
    } catch (error) {
      console.error('Error checking' + docField + 'availability:', error);
      return false;
    }
  }
}
