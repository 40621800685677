import { Component, Input } from '@angular/core';
import { SpinnerSvgComponent } from './spinner-svg/spinner-svg.component';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  imports: [SpinnerSvgComponent],
  standalone: true,
})
export class LoadingSpinnerComponent {
  @Input() open: boolean = false;
}
