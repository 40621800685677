import { Injectable } from '@angular/core';
// Angular Fire
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
// CDA Libs
import { COL_NAMES } from '@cda-libs/cda-const';
import { APIResponse, CMSUser, CMSUserRegisterInput } from '@cda-libs/cda-models';
// Firebase
import { FirebaseError } from 'firebase/app';
import firebase from 'firebase/compat/app';
// Rxjs
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { filter } from 'rxjs/internal/operators/filter';


interface AuthAttempOutput {
  validCredentials: boolean;
  email: string;
  uid: string | null;
  errorCode: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  authSub$!: Subscription;
  private _authUser$ = new BehaviorSubject<CMSUser | null | undefined>(undefined)
  private _authState$ = new BehaviorSubject<firebase.User | null | undefined>(undefined);
  private _authUserSub!: Subscription;
  private _authStateSub!: Subscription;

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private afFunc: AngularFireFunctions,
  ) {
    this.initAuth();
  }

  private initAuth() {
    if (this._authStateSub) this._authStateSub.unsubscribe();
    this._authStateSub = this.afAuth.authState
      .subscribe((fbUser) => {
        if (fbUser) {
          this._authState$.next(fbUser); //State of auth becomes true
          
          if (this._authUserSub) this._authUserSub.unsubscribe();
          this._authUserSub = this.afs
            .collection(COL_NAMES.cmsUsers)
            .doc<CMSUser>(fbUser.uid)
            .valueChanges()
            .subscribe((userData) => {
              if (userData) {
                this._authUser$.next(userData || null);
                if (fbUser.emailVerified && this.userIsDisabled(userData.isDeleted)) {
                  this.singOut().then(() => location.reload());
                }
              }
            });
        } else {
          if (this._authUserSub) this._authUserSub.unsubscribe();
          this._authState$.next(null);
          this._authUser$.next(null);
        }
      })
  }

  private userIsDisabled(isDeleted: boolean) {
    return (isDeleted === true);
  }

  async singOut() {
    try {

      this.clearAuthUser();
      this.clearAuthState();

      if (this._authStateSub) this._authStateSub.unsubscribe();
      if (this._authUserSub) this._authUserSub.unsubscribe();

      await this.afAuth.signOut();
      
    } catch (error) {
      console.log(error);
    }
  }

  private clearAuthUser() {
    this._authUser$.next(null);
  }

  /**Clears the auth data to false */
  private clearAuthState() {
    this._authState$.next(null);
  }

  async signUpEmailAndPassword(input: CMSUserRegisterInput): Promise<APIResponse> {
    try {
      const registerCMSUser = (this.afFunc.httpsCallable("registerCMSUser"));
      const result = await firstValueFrom(registerCMSUser(input));

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signInEmailAndPassword(email: string, password: string): Promise<AuthAttempOutput> {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(email, password);

      return Promise.resolve({
        validCredentials: true,
        email,
        uid: result.user!.uid,
        errorCode: null,
      });
    } catch (error) {
      return Promise.resolve({
        validCredentials: false,
        email,
        uid: null,
        errorCode: (error as FirebaseError).code,
      });
    }
  }

  get authState$(): Observable<firebase.User | null | undefined> {
    return this._authState$;;
  }

  get authUser$(): Observable<CMSUser | null> {
    return this._authUser$
      .pipe(
        filter((user) => user !== undefined)
      ) as BehaviorSubject<CMSUser | null>;
  }
}
