<div id="page-container" class="grid m-0">
  <div class="sidebar-wrapper col-3 p-0">
    <app-sidebar></app-sidebar>
  </div>
  <div class="col-3"></div>
  <div class="router-container col-9 p-4">
    <h1 class="title">La Casa del Abuelo</h1>
    <router-outlet></router-outlet>
  </div>
</div>
