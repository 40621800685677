import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CDADocumentOmit, ClientCoupon, ClientCouponStates } from '@cda-libs/cda-models';
import { DatabaseService } from './database.service';
import { collection, serverTimestamp, getDocs } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ClientCouponService {
  private collectionPath: string = 'clientCoupons';
  private clientCouponCollectionRef = collection(
    this.firestore.firestore,
    this.collectionPath
  );

  constructor(
    private databaseService: DatabaseService,
    private firestore: AngularFirestore
  ) {}

  public async getClientCoupon(couponId: string): Promise<ClientCoupon> {
    const docData = await this.databaseService.getDocument(
      this.collectionPath,
      couponId
    );
    const clientCoupon: ClientCoupon = docData as ClientCoupon;
    return clientCoupon;
  }

  public async redeemCoupon(clientCouponId: string) {
    const newStatus: ClientCouponStates = 'used';
    await this.databaseService.updateDocument(
      this.collectionPath,
      { state: newStatus, redeemDate: serverTimestamp() },
      clientCouponId
    );

    return newStatus;
  }

  public generateAlphanumericId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let id = '';

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }

    return id;
  }

  public async createClientCouponWithoutClient(couponId: string) {
    const clientCouponId: string = this.generateAlphanumericId();

    const clientCoupon: Omit<ClientCoupon, CDADocumentOmit> = {
      id: clientCouponId,
      clientId: null,
      couponId: couponId,
      state: 'notAssigned',
      redeemDate: null,
    };

    await this.databaseService.createDocument(
      this.collectionPath,
      clientCoupon,
      clientCouponId
    );

    return clientCoupon;
  }

  public async getAll() {
    const querySnapshot = await getDocs(this.clientCouponCollectionRef);

    return querySnapshot.docs.map((doc) => {
      return doc.data() as ClientCoupon;
    });
  }
}
