import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
} from '@angular/fire/compat/firestore';
import {
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
  getDocs,
  Firestore,
  serverTimestamp,
} from 'firebase/firestore';
import { CDADocumentOmit, Client, ClientCoupon } from '@cda-libs/cda-models';
import { DatabaseService } from './database.service';
import { Observable } from 'rxjs';
import { COL_NAMES } from '@cda-libs/cda-const';
import { writeBatch, doc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private collectionPath: string = 'clients';
  private clientCollectionRef = collection(
    this.firestore.firestore,
    this.collectionPath
  );

  constructor(
    private databaseService: DatabaseService,
    private firestore: AngularFirestore
  ) {}

  public generateAlphanumericId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let id = '';

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }

    return id;
  }

  public async getClient(clientId: string) {
    const docData = await this.databaseService.getDocument(
      this.collectionPath,
      clientId
    );
    const client: Client = docData as Client;
    return client;
  }

  public async getAll() {
    const querySnapshot = await getDocs(this.clientCollectionRef);

    return querySnapshot.docs.map((doc) => {
      return doc.data() as Client;
    });
  }

  public async createClientToCoupon(
    clientData: Omit<Client, CDADocumentOmit>,
    clientCouponId: string
  ) {
    const newClientRef = doc(this.clientCollectionRef);
    const completeClientData = this.databaseService.addCDADocumentAttributes(
      clientData,
      newClientRef.id
    );

    const clientCouponCollectionRef = collection(
      this.firestore.firestore,
      'clientCoupons'
    );

    // Get a new write batch
    const batch = writeBatch(this.firestore.firestore);

    // Set the value of 'NYC'
    batch.set(newClientRef, completeClientData);

    // Update the population of 'SF'
    const newClientCouponRef = doc(clientCouponCollectionRef, clientCouponId);
    batch.update(newClientCouponRef, {
      updatedAt: serverTimestamp(),
      clientId: newClientRef.id,
      state: 'pending'
    });

    // Commit the batch
    await batch.commit();

    console.log('Batch sin Problema');
    return completeClientData;
  }

  public async createClient(
    clientData: Omit<Client, CDADocumentOmit>,
    couponId: string
  ) {
    const newClientRef = doc(this.clientCollectionRef);
    const completeClientData = this.databaseService.addCDADocumentAttributes(
      clientData,
      newClientRef.id
    );

    const clientCouponCollectionRef = collection(
      this.firestore.firestore,
      'clientCoupons'
    );
    const clientCouponId: string = this.generateAlphanumericId();

    const clientCoupon = this.databaseService.addCDADocumentAttributes(
      {
        id: clientCouponId,
        clientId: newClientRef.id,
        couponId: couponId,
        state: 'pending',
        redeemDate: null,
      },
      clientCouponId
    );

    // Get a new write batch
    const batch = writeBatch(this.firestore.firestore);

    // Set the value of 'NYC'
    batch.set(newClientRef, completeClientData);

    // Update the population of 'SF'
    const newClientCouponRef = doc(clientCouponCollectionRef, clientCouponId);
    batch.set(newClientCouponRef, clientCoupon);

    // Commit the batch
    await batch.commit();

    console.log('Batch sin Problema');
    return clientCoupon;
  }

  public async checkCouponIdAvailability(
    clientEmail: string
  ): Promise<boolean> {
    const collectionRef = collection(
      this.firestore.firestore,
      COL_NAMES.clients
    );
    const q = query(collectionRef, where('email', '==', clientEmail));

    try {
      const querySnapshot = await getDocs(q);
      const clients = [];
      querySnapshot.forEach((doc) => {
        clients.push(doc.data());
      });
      const isAvailable = clients.length === 0;
      return isAvailable;
    } catch (error) {
      console.error('Error checking email availability:', error);
      return false;
    }
  }

  public async checkEmailAvailability(clientEmail: string): Promise<boolean> {
    return await this.databaseService.checkUniqueFields(
      this.collectionPath,
      'email',
      clientEmail
    );
  }

  public async checkPhoneNumberAvailability(
    clientPhoneNumber: string
  ): Promise<boolean> {
    return await this.databaseService.checkUniqueFields(
      this.collectionPath,
      'phoneNumber',
      clientPhoneNumber
    );
  }
}
