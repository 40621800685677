import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SIDEBAR_ITEMS } from '../../constants/sidebar.constant';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  sidebarVisible: boolean = false;
  panelItems: MenuItem[] = SIDEBAR_ITEMS;

  constructor(
    private authService: AuthService,
    private router: Router,

  ) {}

  public closeSidebar() {
    this.sidebarVisible = false;
  }

  public openSidebar() {
    this.sidebarVisible = true;
  }

  signOut() {
    this.authService.singOut().then(() => {
      this.router.navigateByUrl('/auth');
    });
  }


}
