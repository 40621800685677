import { Injectable } from '@angular/core';
import { collection, getDocs } from 'firebase/firestore';
import { DatabaseService } from './database.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Coupon } from '@cda-libs/cda-models';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  private collectionPath: string = 'coupons';
  private couponCollectionRef = collection(
    this.firestore.firestore,
    this.collectionPath
  );

  constructor(
    private databaseService: DatabaseService,
    private firestore: AngularFirestore
  ) {}

  public async getCoupons(): Promise<Coupon[]> {
    const docsData = await this.databaseService.getDocuments(
      this.collectionPath
    );
    const coupons: Coupon[] = docsData as Coupon[];
    return coupons;
  }
  public async getAll() {
    const querySnapshot = await getDocs(this.couponCollectionRef);

    return querySnapshot.docs.map((doc) => {
      return doc.data() as Coupon;
    });
  }
}
