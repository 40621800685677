import { Component } from '@angular/core';

@Component({
  selector: 'app-full-navigation',
  templateUrl: './full-navigation.component.html',
  styleUrls: ['./full-navigation.component.scss'],
})
export class FullNavigationComponent {

}
