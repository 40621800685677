import { Component } from '@angular/core';

@Component({
  selector: 'inc-spinner',
  templateUrl: './spinner-svg.component.html',
  styleUrls: ['./spinner-svg.component.scss'],
  standalone: true,
})
export class SpinnerSvgComponent {

}
