import { Component } from '@angular/core';
import { Client, Coupon } from '@cda-libs/cda-models';
import { ClientService, CouponService } from '@cda-libs/cda-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portal-admin';

  products = [{ code: 'hola', name: 'name', category: 'cat', quantity: 0 }];

  clients: Client[] = [];
  coupons: Coupon[] = [];

  constructor(
    private clientService: ClientService,
    private couponService: CouponService
  ) {}

  async ngOnInit() {
    // this.clients = await this.clientService.getAll();
    // console.log(this.clients);

    // this.coupons = await this.couponService.getAll();
    // console.log(this.coupons);
  }
}
