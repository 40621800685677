import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { TableComponent } from './components/table/table.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FullNavigationComponent } from './components/full-navigation/full-navigation.component';

import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PanelMenuModule } from 'primeng/panelmenu';

@NgModule({
  declarations: [TableComponent, SidebarComponent, FullNavigationComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    CoreRoutingModule,
    SidebarModule,
    ButtonModule,
    PanelMenuModule,
  ],
  exports: [TableComponent, SidebarComponent, FullNavigationComponent],
})
export class CoreModule {}
