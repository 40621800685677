<div class="card sidebar-container">
  <h2 class="sidebar-text">CMS</h2>
  <div class="sidebar-content grid m-0 justify-content-between">
    <p-panelMenu [model]="panelItems" class="sidebar-menu col-12"></p-panelMenu>
    <div class="grid justify-content-end col-12">
      <p-button
        styleClass="p-button-secondary p-button-text"
        (click)="signOut()"
        class="log-out-button"
        icon="pi pi-sign-out"
      ></p-button>
    </div>
  </div>
</div>
